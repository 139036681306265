import { useState, useEffect, useRef, Fragment } from "react";
import { getQr, query, reversal } from "./api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
    const MAX_QUERY_COUNT = 100;
    const [qr, setQr] = useState(null);
    const [ref, setRef] = useState(null);
    const [amount, setAmount] = useState(0);
    const isMounted = useRef(false);

    const toastConfig = {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        pauseOnFocusLoss: false,
    };

    const handleClick = async (e) => {
        try {
            const res = await getQr(amount);
            const data = res.data.result.data;

            setQr(`data:image/jpeg;base64,${data.qr_code}`);
            setRef(data.retrieval_ref);
        } catch (error) {
            toast.error("Something went wrong...", toastConfig);
            console.log(error.message);
        }
    };

    useEffect(() => {
        if (isMounted.current) {
            sleep(3000).then(() => queryFunction());
        } else {
            isMounted.current = true;
        }
    }, [ref]); // eslint-disable-line react-hooks/exhaustive-deps

    const queryFunction = async () => {
        try {
            const res = await query(ref);
            const data = res.data.result.data;

            if (
                data.txn_query_resp_code === "09" &&
                data.txn_query_count < MAX_QUERY_COUNT
            ) {
                await sleep(2500);
                queryFunction();
                toast.info("Sending query request to NETS...", toastConfig);
            } else {
                if (data.txn_query_resp_code === "00") {
                    toast.success("Payment success!", toastConfig);
                } else {
                    toast.error(
                        "Payment failed! Sending reversal request...",
                        toastConfig
                    );
                    await sleep(2500);
                    await reversal(ref);
                    toast.success(
                        "Order reversal request successful!",
                        toastConfig
                    );
                    await sleep(2000);
                    reset();
                }
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const reset = () => {
        setQr(null);
        setRef(null);
        setAmount(0);
    };

    return (
        <Fragment>
            <div className='container mt-8'>
                <h1 className='text-3xl font-bold text-center my-5'>
                    Hello world
                </h1>
                <div className='flex justify-center'>
                    <div className='card w-96 bg-base-100 shadow-xl'>
                        <figure className='px-10 pt-10'>
                            <img
                                src={
                                    qr ??
                                    "https://sg1.beveat.com/beveat/ePayments/NETS/NPS/images/netspay-img.png"
                                }
                                alt='Shoes'
                                className='rounded-xl'
                            />
                        </figure>
                        <div className='card-body items-center text-center'>
                            <h2 className='card-title'>Nets QR</h2>
                            <input
                                type='number'
                                placeholder='Enter amount'
                                className='input input-bordered my-2'
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                            {ref && <p>Retrieval ref: {ref}</p>}
                            <div className='card-actions'>
                                <button
                                    className='btn btn-primary'
                                    onClick={handleClick}
                                >
                                    Retrieve QR
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </Fragment>
    );
}

export default App;
