import axios from "axios";

const netsApi = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api/v1/common/payments/nets-qr`,
    headers: {
        "Content-Type": "application/json",
    },
});

export const getQr = (amt) => {
    return netsApi.post("/request", {
        order_no: "ORDER001",
        amount: amt,
        merchant_id: "1819ecbf-b33d-451b-83d5-7e467c61aad5",
    });
};

export const query = (ref) => {
    return netsApi.post("/query", { retrieval_ref: ref });
};

export const reversal = (ref) => {
    return netsApi.post("/reversal", { retrieval_ref: ref });
};
